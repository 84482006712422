import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import UserLayout from 'src/layouts/user/user';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/sections/components/loading-screen';

// ----------------------------------------------------------------------
export const HomePage = lazy(() => import('../../pages/home'));
export const BlogPage = lazy(() => import('../../pages/Blog'));
export const ProfilePage = lazy(() => import('../../pages/Profile'));
export const BlogDetailsPage = lazy(() => import('../../pages/BlogDetailsPage'));
export const PricingPage = lazy(() => import('../../pages/Pricing'));
export const FaqsPage = lazy(() => import('../../pages/faqs'));
export const NotFoundPage = lazy(() => import('../../pages/404'));

// FIREBASE
const LoginPage = lazy(() => import('../../pages/auth/login'));
const RegisterPage = lazy(() => import('../../pages/auth/register'));
const ForgotPasswordPage = lazy(() => import('../../pages/auth/forgot-password'));


// ----------------------------------------------------------------------

export default function Router () {
  return useRoutes([
    // SET INDEX PAGE WITH HOME PAGE
    {
      element: (
        <MainLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [
        {
          path: '/',
          element: (
            <HomePage />
          ),
        },
        {
          path: '/pricing',
          element: (
            <PricingPage />
          ),
        },
        {
          path: '/faqs',
          element: (
            <FaqsPage />
          ),
        },

        {
          path: '/404',
          element: (
            <NotFoundPage />
          ),
        },

        {
          path: 'blog',
          children: [
            {
              element: (
                <BlogPage />
              ), index: true
            },
            {
              path: ':title', element: (
                <BlogDetailsPage />
              )
            },
          ],
        },
      ],
    },
    {
      element: (
        <UserLayout>
          <Outlet />
        </UserLayout>
      ),
      children: [
        {
          path: '/profile',
          element: (
            <ProfilePage />
          ),
        },
      ],
    },

    {
      element: (
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          path: 'login',
          element: (
            <AuthClassicLayout>
              <LoginPage />
            </AuthClassicLayout>
          ),
        }, {

          path: 'register',
          element: (
            <AuthClassicLayout title="Revolutionize Your LinkedIn Recruitment Now">
              <RegisterPage />
            </AuthClassicLayout>
          ),
        },
        {
          path: 'forgot-password', element: (
            <AuthClassicLayout>
              <ForgotPasswordPage />
            </AuthClassicLayout>
          )
        },
      ]
    },

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
