import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/sections/hooks/use-responsive';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, showText = true, sx, ...other }, ref) => {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'sm');

  // check if light theme 
  // if light theme, use logo with dark background
  // if dark theme, use logo with light background


  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      alt="logo"
      src={theme.palette.mode === 'light' ? 'assets/images/icon-34.png' : 'assets/images/favicon-white.png'}
      sx={{ width: 45, height: 45, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink || !mdUp) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
      {showText && (
        <Typography variant="h6" sx={{ color: theme.palette.mode === 'light' ? 'text.primary' : 'text.secondary', ml: 0, mt: 1, fontWeight: 600 }}>
          HireHarmonyAI
        </Typography>
      )}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  showText: PropTypes.bool
};

export default Logo;
