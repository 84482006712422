import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { varHover } from 'src/sections/components/animate';
import { useSettingsContext } from 'src/sections/components/settings';

import Iconify from '../../sections/components/iconify/iconify';
// ----------------------------------------------------------------------

export default function SettingsButton ({ sx }) {
  const settings = useSettingsContext();
  const theme = useTheme();

  return (
    // <Badge
    //   color="error"
    //   variant="dot"
    //   invisible={!settings.canReset}
    //   sx={{
    //     [`& .${badgeClasses.badge}`]: {
    //       top: 8,
    //       right: 8,
    //     },
    //     ...sx,
    //   }}
    // >
    // </Badge>
    // <Box
    //   component={m.div}
    //   // animate={{
    //   //   rotate: [0, settings.open ? 0 : 360],
    //   // }}
    //   transition={{
    //     duration: 12,
    //     ease: 'linear',
    //     repeat: Infinity,
    //   }}
    // >
    <IconButton
      component={m.button}
      whileTap="tap"
      whileHover="hover"
      variants={varHover(1.05)}
      aria-label="settings"
      onClick={() => settings.onUpdate('themeMode', theme.palette.mode === 'dark' ? "light" : "dark")}
      sx={{
        width: 40,
        height: 40,
        mr: 1
      }}
    >
      <Iconify icon={theme.palette.mode === "light" ? "ph:sun" : "ph:moon"} width={24} />
    </IconButton>
    // </Box>
  );
}

SettingsButton.propTypes = {
  sx: PropTypes.object,
};
