import { Box } from "@mui/material";
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import Logo from 'src/sections/components/logo';

// ----------------------------------------------------------------------

const LINKS = [
  // {
  //   headline: 'Minimal',
  //   children: [
  //     { name: 'About us', href: paths.root },
  //     { name: 'Contact us', href: paths.root },
  //     { name: 'FAQs', href: paths.root },
  //   ],
  // },
  {
    headline: 'Legal',
    children: [
      // { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: paths.terms },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'support@hireharmonyai.com', href: 'mailto:support@hireharmonyai.com' }],
  },
];

// ----------------------------------------------------------------------

export default function Footer () {

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 4,
          pb: 4,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo sx={{ mb: 3 }} />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={12} md={4}>
            <Typography
              variant="body2"
              sx={{
                mx: { xs: 'auto', md: 'unset' },
                mb: { xs: 3, md: 0 },
              }}
            >
              Understanding the unique challenges faced by recruiters, HireHarmonyAI was crafted by industry professionals.
              to address the specific pain points and intricacies of the recruitment process.
            </Typography>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      target='_blank'
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 2 }}>
          © 2023. All rights reserved
          <Link href="https://www.voolist.com"
            sx={{ ml: 0.5, mr: 0.5 }}
            target="_blank" rel="noopener noreferrer" color="primary" >
            HireharmonyAI
          </Link>
        </Typography>

      </Container>
    </Box>
  );

  return mainFooter;
}
