import { Box } from "@mui/material";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Logo from 'src/sections/components/logo';

// ----------------------------------------------------------------------

const LINKS = [

  {
    headline: 'Any Questions? Feel free to reach out!',
    children: [{ name: 'hello@hireharmonyai.com', href: 'mailto:hello@hireharmonyai.com' }],
  },
];

// ----------------------------------------------------------------------

export default function Footer () {

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{

          textAlign: { xs: 'center', md: 'unset' },
        }}
      >


        <Grid xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              // alignItems: "baseline",
              flexDirection: 'row',
              textAlign: 'center',
              alignContent: 'center',
              padding: 2
            }}
          >

            <Logo disabledLink />
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              <Stack
                spacing={2}
                alignItems={{ xs: 'center', md: 'flex-start' }}
                sx={{ width: 1 }}
              >
                <Typography component="div" variant="overline">
                  Any Questions?
                </Typography>
                <Typography variant="body2">
                  support@hireharmonyai.com
                </Typography>

              </Stack>
            </Stack>
          </Box>
        </Grid>


      </Container>
    </Box>
  );

  return mainFooter;
}
