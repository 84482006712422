import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Box } from "@mui/material";

import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';

import Header from './header';
import Footer from './footer';
import { useAuthContext } from "../../auth/hooks/use-auth-context";

// ----------------------------------------------------------------------

export default function UserLayout ({ children }) {
  const pathname = usePathname();
  const Navigate = useNavigate();
  const homePage = pathname === '/';
  const { user } = useAuthContext();

  if (!user) {
    Navigate(paths.login)
  }
  if (user && !user.subscription) {
    Navigate(paths.pricing)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!homePage && {
            pt: { xs: 8, md: 10 },
          }),
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
}

UserLayout.propTypes = {
  children: PropTypes.node,
};
