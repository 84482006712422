
// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
const { REACT_APP_FIREBASE_AUTH_DOMAIN } = process.env
const { REACT_APP_FIREBASE_API_KEY } = process.env
const { REACT_APP_PROJECT_ID } = process.env
const { REACT_APP_FIREBASE_STORAGE_BUCKET } = process.env
const { REACT_APP_FIREBASE_MESSAGING_SENDER_ID } = process.env
const { REACT_APP_FIREBASE_API_ID } = process.env

export const FIREBASE_API = {
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  apiKey: REACT_APP_FIREBASE_API_KEY,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_API_ID,
};


export const { REACT_APP_STRIPE_SECRET_KEY } = process.env


// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = "/profile"; // as '/dashboard'
