import CryptoJS from "crypto-js";
import PropTypes from 'prop-types';
import { initializeApp } from 'firebase/app';
import { useMemo, useEffect, useReducer, useCallback } from 'react';
import { doc, query, where, addDoc, getDoc, setDoc, getDocs, collection, onSnapshot, getFirestore } from 'firebase/firestore';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

import { FIREBASE_API } from 'src/config-global';

import { AuthContext } from './auth-context';

// const stripe = new Stripe(REACT_APP_STRIPE_SECRET_KEY);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);
const AUTH = getAuth(firebaseApp);
const DB = getFirestore(firebaseApp);

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
  shouldEnqueueSnackbar: {
    open: false,
    message: "",
    variant: "info",
    autoHideDuration: 3000
  },
};


const getCustomer = (uid) => new Promise((resolve, reject) => {
  if (!uid) {
    reject(new Error('User ID not found'));
    return;
  }
  const subscriptionQuery = query(
    collection(DB, "customers", uid, "payments"),
    where("status", "==", "succeeded")
  );

  const unsubscribe = onSnapshot(subscriptionQuery, async (snapshot) => {
    unsubscribe();  // Stop listening to further changes

    if (snapshot.empty) {
      resolve(null);
      return;
    }
    const subscriptionDoc = snapshot.docs[0].data();
    const subscription = {
      status: subscriptionDoc?.status,
    };

    resolve(subscription);
  });
});

async function fetchSubscription (id) {
  const user_subscription = await getCustomer(id);
  return user_subscription || null;
}

const isCustomerExist = async (uid) => {
  const customerDoc = doc(DB, "customers", uid);
  const customerSnap = await getDoc(customerDoc);
  const customer = customerSnap.data();
  if (!customer) {
    return false;
  }
  return true;
}


// ----------------------------------------------------------------------

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_ENQUEUE_SNACKBAR':
      return {
        ...state,
        shouldEnqueueSnackbar: action.payload,
      };
    // Handle other actions
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

export function AuthProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // Listener for auth state changes
    const unsubscribe = onAuthStateChanged(AUTH, async (user) => {
      if (user) {
        // User is signed in, dispatch user data to the reducer
        dispatch({ type: 'SET_LOADING', payload: true });
        try {
          const { uid, displayName, email } = user;
          const subscription = await fetchSubscription(uid);
          // get user by uid
          const userDoc = doc(DB, "users", uid);
          const userSnap = await getDoc(userDoc);
          const apiKey = userSnap.data();
          const cookieValue = JSON.stringify({ uid, displayName, email, ...subscription, apiKey: apiKey?.apiKey });
          const ciphertext = CryptoJS.AES.encrypt(cookieValue, process.env.REACT_APP_CRYPTO_SALT).toString();
          document.cookie = `customer=${ciphertext}; max-age=86400; path=/; secure; samesite=strict`;
          dispatch({ type: 'SET_USER', payload: { ...user, subscription, apiKey: apiKey?.apiKey } });
          dispatch({ type: 'SET_LOADING', payload: false });

        } catch (error) {
          dispatch({ type: 'SET_USER', payload: null });
          dispatch({ type: 'SET_LOADING', payload: false });
        }
      } else {
        // User is signed out, dispatch null
        document.cookie = "customer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; samesite=strict";
        dispatch({ type: 'SET_USER', payload: null });
      }
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);


  async function createCheckoutSession (price_id, mode, uid, email) {
    try {

      // set loading to true 
      dispatch({ type: 'SET_LOADING', payload: true });


      const { currentUser } = AUTH;
      if (!currentUser && !uid && !email) {
        dispatch({
          type: 'SET_ENQUEUE_SNACKBAR', payload: {
            open: true,
            message: "Sign up in one click to get started",
            variant: "info",
            autoHideDuration: 3000
          }
        });
        window.location = "/register";
        return;
      };

      if (!uid) uid = currentUser?.uid;
      if (!email) email = currentUser?.email;

      const stripeCheckoutSession = {
        mode: "payment",
        price: price_id,
        success_url: `${window.location.origin}/profile`,
        cancel_url: window.location.origin,
        client_reference_id: uid,
        customer_email: email,
        allow_promotion_codes: true,
        metadata: {
          uid,
          email,
        },
      };

      dispatch({
        type: 'SET_ENQUEUE_SNACKBAR', payload: {
          open: true,
          message: "Creating checkout session, please wait...",
          variant: "info",
          autoHideDuration: 5000
        }
      });


      const docRef = await addDoc(collection(DB, "customers", uid, "checkout_sessions"), stripeCheckoutSession);

      // Wait for the CheckoutSession to get attached by the extension
      onSnapshot(doc(DB, "customers", uid, "checkout_sessions", docRef.id), (snap) => {
        const { error, url } = snap.data();
        if (error) {
          dispatch({ type: 'SET_LOADING', payload: false });
          dispatch({
            type: 'SET_ENQUEUE_SNACKBAR', payload: {
              open: true,
              message: "An error occurred while creating a CheckoutSession",
              variant: "error",
              autoHideDuration: 3000
            }
          });
        }
        if (!url && !error) {
          dispatch({ type: 'SET_LOADING', payload: true });
        };
        if (url) {
          dispatch({ type: 'SET_LOADING', payload: false });
          window.location = url;
          window.close();
        }

      });
    } catch (error) {
      dispatch({
        type: 'SET_ENQUEUE_SNACKBAR', payload: {
          open: true,
          message: "An error occurred while creating a CheckoutSession",
          variant: "error",
          autoHideDuration: 3000

        }
      });
    }
  }
  // LOGIN
  const login = useCallback(async (email, password) => {
    try {

      const q = query(collection(DB, "users"), where("email", "==", email));
      const docs = await getDocs(q);

      if (docs.docs.length === 0) {
        dispatch({
          type: 'SET_ENQUEUE_SNACKBAR', payload: {
            open: true,
            message: "User does not exist. Please sign up to get started",
            variant: "info",
            autoHideDuration: 3000

          }
        });

        window.location = "/register";
        return;
      }
      // check if user used google auth to sign up
      const user = docs.docs[0].data();
      if (user.authProvider === "google") {

        dispatch({
          type: 'SET_ENQUEUE_SNACKBAR', payload: {
            open: true,
            message: "User signed up with google. Please use google",
            variant: "info",
            autoHideDuration: 3000
          }
        });

        return;
      }

      const { uid } = (await signInWithEmailAndPassword(AUTH, email, password)).user
      await isCustomerExist(uid).then((exists) => {
        if (!exists) {
          createCheckoutSession("price_1P0n40JgjiR5JIS90alNk1Qq", "payment", uid, email);
        } else {
          window.location = "/profile"
        }
      });

    } catch (error) {
      dispatch({
        type: 'SET_ENQUEUE_SNACKBAR', payload: {
          open: true,
          message: "Invalid email or password",
          variant: "error",
          autoHideDuration: 3000

        }
      });

    }
  }, []);

  const loginWithGoogle = useCallback(async () => {
    try {

      const provider = new GoogleAuthProvider();
      const res = await signInWithPopup(AUTH, provider);
      const { uid, displayName, email } = res.user;
      const q = query(collection(DB, "users"), where("uid", "==", uid));
      const docs = await getDocs(q);

      if (docs.docs.length === 0) {
        await addDoc(collection(DB, "users"), {
          uid,
          name: displayName,
          authProvider: "google",
          email,
        });
      }

      await isCustomerExist(uid).then((exists) => {
        if (!exists) {
          createCheckoutSession("price_1P0n40JgjiR5JIS90alNk1Qq", "payment", uid, email);
        } else {
          window.location = "/profile"
        }
      });

    } catch (error) {

      // console.log(error);
    }

  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    try {

      const q = query(collection(DB, "users"), where("email", "==", email));
      const docs = await getDocs(q);

      if (docs.docs.length === 0) {
        const newUser = await createUserWithEmailAndPassword(AUTH, email, password);
        const { uid } = newUser.user;

        await addDoc(collection(DB, "users"), {
          uid,
          name: `${firstName} ${lastName}`,
          authProvider: "local",
          email,
        });

        isCustomerExist(uid).then((exists) => {
          if (!exists) {
            createCheckoutSession("price_1P0n40JgjiR5JIS90alNk1Qq", "payment", uid, email);
          } else {
            window.location = "/profile"
          }
        });
      } else {
        dispatch({
          type: 'SET_ENQUEUE_SNACKBAR', payload: {
            open: true,
            message: "User already exists. Please sign in to continue",
            variant: "info",
            autoHideDuration: 3000


          }
        });

        window.location = "/login";
      }
    } catch (error) {
      // console.log(error);
    }

  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    // remove cookie
    document.cookie = "customer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; samesite=strict";
    await signOut(AUTH);
    window.location = "/";

  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);


  // add function to add api key to user
  const addAPIKey = useCallback(async (uid, apiKey) => {
    try {
      const userDoc = doc(DB, "users", uid);
      await setDoc(userDoc, { apiKey }, { merge: true });

      const subscription = await fetchSubscription(uid);
      // get user by uid from auth
      const user = AUTH.currentUser;
      const { displayName, email } = user;

      const cookieValue = JSON.stringify({ uid, displayName, email, ...subscription, apiKey });
      const ciphertext = CryptoJS.AES.encrypt(cookieValue, process.env.REACT_APP_CRYPTO_SALT).toString();
      document.cookie = `customer=${ciphertext}; max-age=86400; path=/; secure; samesite=strict`;
      dispatch({ type: 'SET_USER', payload: { ...user, subscription, apiKey } });



    } catch (error) {

      dispatch({
        type: 'SET_ENQUEUE_SNACKBAR', payload: {
          open: true,
          message: "An error occurred while adding API key",
          variant: "error",
          autoHideDuration: 3000

        }
      });
    }
  }, []); // eslint-disable-line

  const resetSnackbar = useCallback(() => {
    dispatch({
      type: 'SET_ENQUEUE_SNACKBAR', payload: {
        open: false,
        message: "",
        variant: "info",
        autoHideDuration: 3000
      }
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;
  const showSnackbar = state.shouldEnqueueSnackbar;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      showSnackbar,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      createCheckoutSession,
      addAPIKey,
      resetSnackbar
    }),
    [
      status,
      state.user,
      showSnackbar,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      addAPIKey,
      resetSnackbar
    ]
  );
  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
