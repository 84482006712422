import { paramCase } from 'src/sections/utils/change-case';

// ----------------------------------------------------------------------


export const paths = {
  root: '/',
  profile: '/profile',
  pricing: '/pricing',
  faqs: '/faqs',
  login: `/login`,
  register: `/register`,
  terms: "https://www.privacypolicies.com/live/2ff08c30-4910-447d-a8ed-bda810718398",
  chromeExtension: "https://chrome.google.com/webstore/detail/hireharmonyai/mgahppggpiobaplkbcpejfdaghdcbpmm",
  forgotPassword: `/forgot-password`,
  blog: {
    root: `/blog`,
    details: (title) => `/blog/${paramCase(title)}`,
  },
};
